<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">搜索栏</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">姓名</div>
					<div class="inputs_box">
						<el-input clearable type="text" placeholder="请输入" v-model="forms.keyword" />
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">部门</div>
					<div class="inputs_box">
						<el-select clearable v-model="bumen_select" placeholder="请选择">
							<el-option v-for="item in bumen_list" :key="item.value" :label="item.title" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="search_flex">
					<div class="texts">时间</div>
					<div class="inputs_box">
						<!--                        <el-date-picker :editable="false" 
                        value-format="yyyy-MM-dd" :default-time="['00:00:00','23:59:59']" v-model="times" type="daterange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker> -->
						<el-date-picker v-model="month_select" value-format="yyyy-MM-dd" type="month" placeholder="选择月"  :picker-options="pickerOptions">
						</el-date-picker>
					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
				<div class="rights">
					<div class="btns" @click="do_import_fuli()">
						导入节假日福利
					</div>
					<div class="btns" @click="do_import_gongzibiao()">
						导入工资表
					</div>
					<div class="btns" @click="do_import_chengben()">
						导入成本项目
					</div>
				</div>
			</div>


			<!-- <div class="filter-box">
				<div class="filter-group" v-for="(group,gindex) in column_group">
					<div class="filter-label">
						{{group.title}}
					</div>
					<div class="filter-items">
						<el-checkbox-group v-model="group.checkList">
						    <el-checkbox v-for="(item, index) in  group.child" :label="item.title"></el-checkbox>
						  </el-checkbox-group>
					</div>
				</div>
			</div> -->

			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<!-- <el-table-column type="selection" width="55" label="序号">
					</el-table-column> -->
					<el-table-column fixed prop="id" label="月份" width="120"></el-table-column>
					<el-table-column fixed prop="id" label="姓名" width="120"></el-table-column>
					<el-table-column prop="name" label="部门" width="120"></el-table-column>
					<el-table-column prop="mobile" label="岗位" width="120"></el-table-column>
					<el-table-column prop="mobile" label="出勤" width="120"></el-table-column>
					<el-table-column prop="created_at" label="应付工资" width="">
						<el-table-column prop="created_at" label="定额工资" width=""></el-table-column>
						<el-table-column prop="created_at" label="结算工资" width=""></el-table-column>
						<el-table-column prop="created_at" label="传票工资" width=""></el-table-column>
						<el-table-column prop="created_at" label="基本工资" width=""></el-table-column>
						<el-table-column prop="created_at" label="岗位工资" width=""></el-table-column>
						<el-table-column prop="created_at" label="工龄工资" width=""></el-table-column>
						<el-table-column prop="created_at" label="司龄工资" width=""></el-table-column>
						<el-table-column prop="created_at" label="岗位补贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="年休假工资" width="120"></el-table-column>
						<el-table-column prop="created_at" label="其他固定工资" width="120"></el-table-column>
						<el-table-column prop="created_at" label="计件工资" width=""></el-table-column>
						<el-table-column prop="created_at" label="其他计件工资" width="120"></el-table-column>
						<el-table-column prop="created_at" label="品位奖罚" width=""></el-table-column>
						<el-table-column prop="created_at" label="产量奖罚" width=""></el-table-column>
						<el-table-column prop="created_at" label="安全奖罚" width=""></el-table-column>
						<el-table-column prop="created_at" label="月度绩效" width=""></el-table-column>
						<el-table-column prop="created_at" label="季度绩效" width=""></el-table-column>
						<el-table-column prop="created_at" label="年度绩效" width=""></el-table-column>
						<el-table-column prop="created_at" label="月度奖金" width=""></el-table-column>
						<el-table-column prop="created_at" label="季度奖金" width=""></el-table-column>
						<el-table-column prop="created_at" label="年度奖金" width=""></el-table-column>
						<el-table-column prop="created_at" label="科技创新奖金" width="120"></el-table-column>
						<el-table-column prop="created_at" label="合理化建议奖金" width="120"></el-table-column>
						<el-table-column prop="created_at" label="红旗设备奖金" width="120"></el-table-column>
						<el-table-column prop="created_at" label="安全环保奖金" width="120"></el-table-column>
						<el-table-column prop="created_at" label="年度其他奖金" width="120"></el-table-column>
						<el-table-column prop="created_at" label="任期激励奖金" width="120"></el-table-column>
						<el-table-column prop="created_at" label="成本奖罚" width=""></el-table-column>
						<el-table-column prop="created_at" label="指标奖罚" width=""></el-table-column>
						<el-table-column prop="created_at" label="党建考核奖罚" width="120"></el-table-column>
						<el-table-column prop="created_at" label="安全环保奖罚" width="120"></el-table-column>
						<el-table-column prop="created_at" label="其他浮动工资" width="120"></el-table-column>
						<el-table-column prop="created_at" label="交通补助" width=""></el-table-column>
						<el-table-column prop="created_at" label="通讯补助" width=""></el-table-column>
						<el-table-column prop="created_at" label="节日补助" width=""></el-table-column>
						<el-table-column prop="created_at" label="山区补贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="下井补贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="生活补贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="装卸补贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="出车补贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="稿费" width=""></el-table-column>
						<el-table-column prop="created_at" label="大病补贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="大病补贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="职称津贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="高温津贴" width=""></el-table-column>
						<el-table-column prop="created_at" label="女工费" width=""></el-table-column>
						<el-table-column prop="created_at" label="疾病补助" width=""></el-table-column>
						<el-table-column prop="created_at" label="扶贫补助" width=""></el-table-column>
						<el-table-column prop="created_at" label="其他津补贴" width="120"></el-table-column>
						<el-table-column prop="created_at" label="其他奖罚" width=""></el-table-column>
						<el-table-column prop="created_at" label="加班值班费" width="120"></el-table-column>
						<el-table-column prop="created_at" label="福利费" width=""></el-table-column>
						<el-table-column prop="created_at" label="补扣工资" width="120"></el-table-column>
						<el-table-column prop="created_at" label="其他工资总额" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="created_at" label="五险一金" width="">
						<el-table-column prop="created_at" label="养老" width=""></el-table-column>
						<el-table-column prop="created_at" label="失业" width=""></el-table-column>
						<el-table-column prop="created_at" label="医疗" width=""></el-table-column>
						<el-table-column prop="created_at" label="公积金" width=""></el-table-column>
					</el-table-column>

					<el-table-column prop="created_at" label="代扣工资" width="">
						<el-table-column prop="created_at" label="税金" width=""></el-table-column>
						<el-table-column prop="created_at" label="生活费" width=""></el-table-column>
						<el-table-column prop="created_at" label="退税" width=""></el-table-column>
						<el-table-column prop="created_at" label="党费" width=""></el-table-column>
					</el-table-column>

					<el-table-column prop="created_at" label="考勤扣款" width="">
						<el-table-column prop="created_at" label="事假扣款" width=""></el-table-column>
						<el-table-column prop="created_at" label="病假扣款" width=""></el-table-column>
						<el-table-column prop="created_at" label="迟到早退扣款" width="120"></el-table-column>
						<el-table-column prop="created_at" label="旷工扣款" width=""></el-table-column>
						<el-table-column prop="created_at" label="其他考勤扣款" width="120"></el-table-column>
					</el-table-column>

					<el-table-column prop="created_at" label="应发工资" width=""></el-table-column>
					<el-table-column prop="created_at" label="实发工资" width=""></el-table-column>


					<!-- 操作模块   -->
					<!-- <el-table-column fixed="right" label="操作" width="140">
						<template slot-scope="scope">
							<el-button type="text" size="small" class="btn_modify"
								@click="handle_btn_modify(scope.row)">编辑</el-button>
							<el-button type="text" size="small" class="btn_delete"
								@click="handle_btn_delete(scope.row)">减员</el-button>
						</template>
					</el-table-column> -->
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


		<!--  -->
		<shebao_user_add_modal data-title="社保增员" ref="shebao_user_add_modal" />
		<shebao_user_delete_modal data-title="社保减员" ref="shebao_user_delete_modal" />

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	import shebao_user_add_modal from "@/components/shebao/shebao_user_add_modal.vue"; //增员
	import shebao_user_delete_modal from "@/components/shebao/shebao_user_delete_modal.vue"; //减员


	export default {
		components: {
			page,
			shebao_user_add_modal,
			shebao_user_delete_modal,
		},
		data() {
			return {
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
					identity: '',
				},
				list: [{
						name: '123123'
					},
					{
						name: '123123'
					}, {
						name: '123123'
					}, {
						name: '123123'
					},
				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围


				//
				month_select: '',
				date_list: [
					// {date: '2024-05-01', week: '星期日',},
				],

				//
				bumen_list: [{
						title: '部门1',
						value: '1'
					},
					{
						title: '部门2',
						value: '2'
					},
				],
				bumen_select: '',
				
				
				//字段
				column_group: [
					{
						title: '基本信息',
						checkList: [],
						child: [
							{column: '', title: '姓名',},
							{column: '', title: '部门',},
							{column: '', title: '岗位',},
							{column: '', title: '出勤',},
						]
					},
					{
						title: '应付工资',
						checkList: [],
						child: [
							{column: '', title: '定额工资'},
							{column: '', title: '结算工资'},
							{column: '', title: '传票工资'},
							{column: '', title: '基本工资'},
							{column: '', title: '岗位工资'},
							{column: '', title: '工龄工资'},
							{column: '', title: '司龄工资'},
							{column: '', title: '岗位补贴'},
							{column: '', title: '年休假工资'},
							{column: '', title: '其他固定工资'},
							{column: '', title: '计件工资'},
							{column: '', title: '其他计件工资'},
							{column: '', title: '品位奖罚'},
							{column: '', title: '产量奖罚'},
							{column: '', title: '安全奖罚'},
							{column: '', title: '月度绩效'},
							{column: '', title: '季度绩效'},
							{column: '', title: '年度绩效'},
							{column: '', title: '月度奖金'},
							{column: '', title: '季度奖金'},
							{column: '', title: '年度奖金'},
							{column: '', title: '科技创新奖金'},
							{column: '', title: '合理化建议奖金'},
							{column: '', title: '红旗设备奖金'},
							{column: '', title: '安全环保奖金'},
							{column: '', title: '年度其他奖金'},
							{column: '', title: '任期激励奖金'},
							{column: '', title: '成本奖罚'},
							{column: '', title: '指标奖罚'},
							{column: '', title: '党建考核奖罚'},
							{column: '', title: '安全环保奖罚'},
							{column: '', title: '其他浮动工资'},
							{column: '', title: '交通补助'},
							{column: '', title: '通讯补助'},
							{column: '', title: '节日补助'},
							{column: '', title: '山区补贴'},
							{column: '', title: '下井补贴'},
							{column: '', title: '生活补贴'},
							{column: '', title: '装卸补贴'},
							{column: '', title: '出车补贴'},
							{column: '', title: '稿费'},
							{column: '', title: '大病补贴'},
							{column: '', title: '大病补贴'},
							{column: '', title: '职称津贴'},
							{column: '', title: '高温津贴'},
							{column: '', title: '女工费'},
							{column: '', title: '疾病补助'},
							{column: '', title: '扶贫补助'},
							{column: '', title: '其他津补贴'},
							{column: '', title: '其他奖罚'},
							{column: '', title: '加班值班费'},
							{column: '', title: '福利费'},
							{column: '', title: '补扣工资'},
							{column: '', title: '工资总额'},
						]
					},
					{
						title: '五险一金',
						checkList: [],
						child: [
							{column: '', title: '养老',},
							{column: '', title: '失业',},
							{column: '', title: '医疗',},
							{column: '', title: '公积金',},
						]
					},
					{
						title: '代扣工资',
						checkList: [],
						child: [
							{column: '', title: '税金',},
							{column: '', title: '生活费',},
							{column: '', title: '退税',},
							{column: '', title: '党费',},
						]
					},	
					{
						title: '考勤扣款',
						checkList: [],
						child: [
							{column: '', title: '事假扣款',},
							{column: '', title: '病假扣款',},
							{column: '', title: '迟到早退扣款',},
							{column: '', title: '旷工扣款',},
							{column: '', title: '其他考勤扣款',},
						]
					},
				],
				pickerOptions: {
					disabledDate(time) {
						console.log('time', time)
						return (time.getMonth() ) > new Date().getMonth();
					}
				},
			};
		},

		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				this.set_date_list();


				console.log(this.forms)
				this.loading = true
				this.$api("getEmployeeList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},




			set_date_list() {
				let month_value = this.month_select.substring(0, 7);
				let day_number = 30;
				let date_list = []
				for (var i = 1; i <= day_number; i++) {
					let day = i < 10 ? '0' + i : i;
					let date = `${month_value}-${day}`;
					let week_number = dayjs(date).day()
					let week_text = {
						0: '星期日',
						1: '星期一',
						2: '星期二',
						3: '星期三',
						4: '星期四',
						5: '星期五',
						6: '星期六',
					} [week_number]
					let info = {
						date: date,
						week: week_text,
					}
					date_list.push(info)
				}
				this.date_list = date_list;

				console.log('当前月份日期列表', JSON.parse(JSON.stringify(this.date_list)))
			},


			//编辑
			handle_btn_modify(row) {
				this.$refs.shebao_user_add_modal.init(row)
			},
			//减员
			handle_btn_delete(row) {
				this.$refs.shebao_user_delete_modal.init(row)
			},

		
			//导入节假日福利
			do_import_fuli() {
				
			},		
			//导入工资表
			do_import_gongzibiao() {

			},	
			//导入成本项目
			do_import_chengben() {

			},
		},
		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			// this.get_list()
		},
		mounted() {

		}


	};
</script>

<style scoped lang="less">
	.filter-box {
		padding: 30px 20px;
		padding-bottom: 0;
		
		.filter-group {
			margin-bottom: 10px;
			display: flex;
			align-items: flex-start;
			.filter-label {
				min-width: 100px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
			}
			.filter-items {
				flex:1;
				overflow: hidden;
				
				.el-checkbox {
					margin-bottom: 20px;
				}
			}
		}
	}
	
	
	
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						margin-left: 20px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>